<template>
    <div class="card mh-100 overflow-auto rounded-xl shadow-sm">
        <div class="card-header bg-light position-sticky d-flex justify-content-between align-items-center pl-3 pr-0 py-2">
            <div>
                <slot></slot>
            </div>
            <div class="d-flex align-items-center">
                <span class="mr-2">
                    {{ allowReorder ? "Reordering" : "Reorder" }}
                </span>
                <b-form-checkbox size="lg"
                                 switch
                                 v-model="allowReorder">
                </b-form-checkbox>
            </div>
        </div>
        <draggable tag="ul"
                   class="list-group list-group-flush"
                   draggable=".item"
                   v-bind:disabled="!allowReorder"
                   v-model="attributes"
                   v-on:start="drag=true"
                   v-on:end="drag=false">
            <attribute-item class="item"
                            v-for="({ name, value }, index) in attributes"
                            v-bind:type="type"
                            v-bind:key="index"
                            v-bind:id="index"
                            v-bind:name="name"
                            v-bind:value="value"
                            v-on:done="updateAttribute"
                            v-on:delete="deleteAttribute">
            </attribute-item>
            <attribute-item slot="footer"
                            class="group-item-add position-sticky"
                            editable
                            v-bind:type="type"
                            v-on:done="addAttribute">
            </attribute-item>
        </draggable>
    </div>
</template>

<script>
import { BFormCheckbox, } from "bootstrap-vue";
import draggable from "vuedraggable";
import AttributeItem from "./AttributeItem";
export default {
    name: "AttributeStack",
    components: {
        BFormCheckbox,
        draggable,
        AttributeItem,
    },
    props: {
        "value" :{
            type: Array,
        },
    },
    data () {
        return {
            allowReorder: false,
            isLoading: false,
        };
    },
    computed: {
        attributes: {
            get () {
                return this.value;
            },
            set (val) {
                this.$emit("input", val);
            }
        },
    },
    methods: {
        addAttribute ({ update }) {
            this.attributes.push(update);
        },
        updateAttribute ({ index, update }) {
            this.attributes.splice(index, 1, update);
        },
        deleteAttribute (index) {
            this.attributes.splice(index, 1);
        },
    },
}
</script>

<style lang="scss" scoped>
.card-header {
    top: 0;
    z-index: 1;
}

.group-item-add {
    bottom: 0;
    z-index: 1;
}

.btn-add {
    background-color: rgba(255, 255, 255, 1);

    &:hover,
    &:focus {
        background-color: #343a40;
    }
}
</style>
