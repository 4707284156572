<template>
    <li class="list-group-item px-2 py-2"
        v-bind:class="{ 'bg-light': isEdit }">
        <div class="d-flex align-items-center">
            <div class="flex-grow-1 mr-2">
                <div v-if="!isEdit">
                    <strong>{{ name }}:</strong> {{ value }}
                </div>
                <div v-else>
                    <b-form-input class="rounded-pill pl-3"
                                  placeholder="Name"
                                  v-bind:required="isEdit && !!name"
                                  size="sm"
                                  v-bind:disabled="isLoading"
                                  v-model="nameInComponent">
                    </b-form-input>
                </div>
            </div>
            <div class="text-nowrap">
                <button class="btn btn-sm btn-outline-warning border-0"
                        type="button"
                        v-if="!isEdit"
                        v-bind:class="{ 'btn-loading': isLoading }"
                        v-on:click="isEdit = true">
                    <font-awesome-icon icon="pencil-alt"></font-awesome-icon>
                </button>
                <button class="btn btn-sm btn-outline-danger border-0"
                        type="button"
                        v-if="!isEdit"
                        v-bind:class="{ 'btn-loading': isLoading }"
                        v-on:click="deleteGroup">
                    <font-awesome-icon icon="trash-alt"></font-awesome-icon>
                </button>
                <button class="btn btn-sm btn-outline-success border-0"
                        type="button"
                        v-if="isEdit"
                        v-bind:class="{ 'btn-loading': isLoading }"
                        v-bind:disabled="!isValueValid"
                        v-on:click="done">
                    <font-awesome-icon icon="check-circle"></font-awesome-icon>
                </button>
                <button class="btn btn-sm btn-outline-danger border-0"
                        type="button"
                        v-if="isEdit"
                        v-bind:disabled="isLoading"
                        v-bind:class="{ 'btn-loading': isLoading }"
                        v-on:click="isEdit = false">
                    <font-awesome-icon icon="times-circle"></font-awesome-icon>
                </button>
            </div>
        </div>
        <b-collapse class="pt-2"
                    v-model="isEdit">
            <b-form-group class="mb-0">
                <b-form-input class="rounded-pill pl-3"
                              placeholder="Value"
                              v-bind:required="isEdit && !!value"
                              size="sm"
                              v-bind:disabled="isLoading"
                              v-model="valueInComponent">
                </b-form-input>
            </b-form-group>
        </b-collapse>
    </li>
</template>

<script>
    import {
        BCollapse,
        BFormGroup,
        BFormInput,
    } from  "bootstrap-vue";

    export default {
        name: "AttributeItem",
        components: {
            BCollapse,
            BFormGroup,
            BFormInput,
        },
        props: {
            "editable": {
                type: Boolean,
                default: undefined,
            },
            "id": {
                type: [String, Number],
            },
            "name": {
                type: String,
            },
            "value": {
                type: String,
            },
        },
        data () {
            return {
                isLoading: false,
                isEditValue: false,

                nameModelValue: "",
                valueModelValue: "",
            };
        },
        computed: {
            invalidLinkFeedback () {
                if (this.valueModelValue.length === 0) {
                    return "Value is required. ";
                }
                return "";
            },
            isValueValid () {
                return this.valueModelValue.length > 0;
            },
            isEdit: {
                get () {
                    if (this.editable !== undefined) {
                        return this.editable;
                    }
                    return this.isEditValue;
                },
                set (val) {
                    this.$emit("update:editable", val);
                    this.isEditValue = val;
                }
            },
            nameInComponent: {
                get () {
                    if (this.nameModelValue) {
                        return this.nameModelValue;
                    }
                    return this.name;
                },
                set (val) {
                    this.nameModelValue = val;
                    this.$emit("update:name", val);
                }
            },
            valueInComponent: {
                get () {
                    if (this.valueModelValue) {
                        return this.valueModelValue;
                    }
                    return this.link;
                },
                set (val) {
                    this.valueModelValue = val;

                }
            }
        },
        methods: {
            deleteGroup () {
                this.$emit("delete", this.id);
            },

            done () {
                this.$emit(
                    "done",
                    {
                        index: this.id,
                        update: {
                           name: this.nameInComponent,
                           value: this.valueInComponent,
                        },
                    }
                );
                this.isEdit = false;
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
